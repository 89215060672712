<template></template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as types from "../store/types";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  SYSTEM_VERSION,
  CALL_TO_ACTION,
  SIMPLE_MESSAGE,
} from "@/constants/announcement_types";

export default {
  setup() {
    const { skin } = useAppConfig();

    return { toast: useToast(), skin };
  },
  computed: {
    ...mapGetters({
      announcementNotifications: types.SYSTEM_VERSION_NOTIFICATIONS,
    }),
  },
  methods: {
    ...mapActions({
      checkAnnouncementNotification: types.CHECK_SYSTEM_VERSION_NOTIFICATIONS,
      readSystemNotification: types.READ_SYSTEM_NOTIFICATION,
    }),
    showNotification() {
      if (!this.$swal.isVisible()) {
        let messageObject = {
          backdrop: true,
          html: this.announcementNotifications.description,
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          width: "60vw",
          background: `${this.skin === "dark" ? "#283046" : ""}`,
          customClass: {
            confirmButton: "btn btn-primary",
            title: "text-primary",
            htmlContainer: `announcement-body ${
              this.skin === "dark" ? "text-white" : ""
            }`,
          },
        };
        if (this.announcementNotifications.type === SYSTEM_VERSION) {
          messageObject.title =
            "Novidades da versão " + this.announcementNotifications.version;
          messageObject.icon = "info";
        } else if (this.announcementNotifications.type === CALL_TO_ACTION) {
          messageObject.confirmButtonText =
            this.announcementNotifications.call_to_action_button_title;
        } else if (this.announcementNotifications.type === SIMPLE_MESSAGE) {
          messageObject.title = "Atenção!";
          messageObject.icon = "warning";
        }
        this.$swal(messageObject).then((result) => {
          if (result.value) {
            this.readSystemNotification(this.announcementNotifications.id)
              .then((result) => {
                this.checkAnnouncementNotification();
              })
              .catch(() => {
                this.toast({
                  component: ToastificationContent,
                  props: {
                    title: "Oops!",
                    text: "Ocorreu um erro ao marcar a notificação. Entre em contato com o setor de TI.",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              });
          }
          if (this.announcementNotifications.type === CALL_TO_ACTION) {
            setTimeout(() => {
              window.location = this.announcementNotifications.call_to_action_link;
            }, 1000);
          }
        });
      }
    },
  },
  watch: {
    announcementNotifications(notification) {
      if (notification.id) {
        this.showNotification();
      }
    },
  },
};
</script>
<style lang="scss">
.announcement-body {
  height: 40vh;
  overflow: auto;
}
.announcement-body h1,
.announcement-body h2,
.announcement-body h3,
.announcement-body h4,
.announcement-body h5,
.announcement-body h6 {
  color: #5e5873 !important;
}
.announcement-body img {
  max-width: 75rem;
}
</style>
